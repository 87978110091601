<template>
  <div class="content5">
    <v-tour :options="tourOptions" name="linkWithApis" :steps="steps"></v-tour>
    <div class="api-title">
      <svg
        width="38"
        height="42"
        viewBox="0 0 38 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.9999 11.5554L18.9999 20.9999M18.9999 20.9999L1.99993 11.5554M18.9999 20.9999L19 39.9999M37 29.117V12.8828C37 12.1975 37 11.8549 36.899 11.5493C36.8097 11.2789 36.6637 11.0308 36.4707 10.8214C36.2526 10.5847 35.9531 10.4183 35.3541 10.0855L20.5541 1.86329C19.9869 1.54817 19.7032 1.39062 19.4029 1.32884C19.1371 1.27417 18.8629 1.27417 18.5971 1.32884C18.2968 1.39062 18.0132 1.54817 17.4459 1.86329L2.64594 10.0855C2.04689 10.4183 1.74737 10.5847 1.52927 10.8214C1.33632 11.0308 1.19029 11.2789 1.10097 11.5493C1 11.8549 1 12.1975 1 12.8828V29.117C1 29.8023 1 30.145 1.10097 30.4506C1.19029 30.7209 1.33632 30.9691 1.52927 31.1785C1.74737 31.4151 2.0469 31.5815 2.64594 31.9143L17.4459 40.1366C18.0132 40.4517 18.2968 40.6092 18.5971 40.671C18.8629 40.7257 19.1371 40.7257 19.4029 40.671C19.7032 40.6092 19.9869 40.4517 20.5541 40.1366L35.3541 31.9143C35.9531 31.5815 36.2526 31.4151 36.4707 31.1785C36.6637 30.9691 36.8097 30.7209 36.899 30.4506C37 30.145 37 29.8023 37 29.117Z"
          stroke="#31006F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h5>ربط التذاكر</h5>
    </div>
    <Tabs :selected="0">
      <Tab title="إنشاء شكوي" :steps="10">
        <link-api
          :link="linkCreateTicket"
          :success="successCreateTicket"
          :apiCode="apiCodeCreateTicekt"
          :data="dataCreateTicekt"
        />
      </Tab>
      <Tab title="انواع الشكاوي" :steps="11">
        <link-api
          :link="linkGetComplaints"
          :success="successGetComplaints"
          :apiCode="apiCodeGetComplaints"
          :data="dataGetComplaints"
        />
      </Tab>
      <Tab title="إنشاء استفسار" :steps="12">
        <link-api
          :link="linkCreateTicket"
          :success="successCreateTicket"
          :apiCode="apiCodeCreateInquirie"
          :data="dataCreateInquirie"
        />
      </Tab>
      <Tab title="انواع الاستفسارات" :steps="13">
        <link-api
          :link="linkGetInquiries"
          :success="successGetInquiries"
          :apiCode="apiCodeGetInquiries"
          :data="dataGetInquiries"
        />
      </Tab>
      <Tab title="إنشاء شكر أو إقتراح" :steps="14">
        <link-api
          :link="linkCreateTicket"
          :success="successCreateTicket"
          :apiCode="apiCodeCreateGratitude"
          :data="dataCreateGratitude"
        />
      </Tab>
      <Tab title="الفروع" :steps="9">
        <link-api
          :link="linkGetBranches"
          :success="successGetBranches"
          :apiCode="apiCodeGetBranches"
          :data="dataGetBranches"
        />
      </Tab>
      <Tab title="Postman">
        <h3 style="display: flex; align-items: center;">
          رابط الAPI للخارجي علي postman
        </h3>
        <p>يمكنك عن طريق هذا الرابط معرفة جميع المعلومات اللازمة لمعرفة كيفية التعامل مع الAPIs.</p>

        <a href="https://documenter.getpostman.com/view/19468765/2s9Ye8hFRy" target="_blank"> https://documenter.getpostman.com/view/19468765/2s9Ye8hFRy</a>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import LinkApi from "@/views/components/LinkApi.vue";
import instantLinkApi from "@/views/components/InstantLinkApi.vue";
import Tabs from "@/layouts/components/Tabs.vue";
import Tab from "@/layouts/components/Tab.vue";
import { mapGetters } from "vuex";
import tour from "@/mixins/tour.js";

export default {
  name: "linkWitsApis",
  mixins: [tour],
  data() {
    return {
      steps: [
        {
          target: '[data-link-api-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "الربط الفوري",
          },
          content: `من هنا يمكنك الربط اللحظي مع تربو`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="2"]', // We're using document.querySelector() under the hood
          header: {
            title: "اضافة طرد ب API",
          },
          content: `من هنا يمكنك اضافة طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="3"]', // We're using document.querySelector() under the hood
          header: {
            title: "بحث عن طرد ب API",
          },
          content: `من هنا يمكنك بحث طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="4"]', // We're using document.querySelector() under the hood
          header: {
            title: "تعديل طرد ب API",
          },
          content: `من هنا يمكنك تعديل طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="5"]', // We're using document.querySelector() under the hood
          header: {
            title: "معرفة حالة الطرد API",
          },
          content: `من هنا يمكنك معرفة حالة الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="6"]', // We're using document.querySelector() under the hood
          header: {
            title: "حذف طرد ب API",
          },
          content: `من هنا يمكنك حذف طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="7"]', // We're using document.querySelector() under the hood
          header: {
            title: "طلب إعادة إرسال طرد ب API",
          },
          content: `من هنا يمكنك طلب إعادة إرسال الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="8"]', // We're using document.querySelector() under the hood
          header: {
            title: "طلب إلغاء طرد ب API",
          },
          content: `من هنا يمكنك طلب إلغاء الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="9"]', // We're using document.querySelector() under the hood
          header: {
            title: "IDS الفروع",
          },
          content: `من هنا يمكنك معرفة ID كل فرع واستخدامه في API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="10"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها شكوي والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="11"]', // We're using document.querySelector() under the hood
          header: {
            title: "أنواع الشكاوي ب API",
          },
          content: `من هنا يمكنك الحصول علي جميع انواع الشكاوي لإنشاء تذكرة دعم عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="12"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها استفسار والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="13"]', // We're using document.querySelector() under the hood
          header: {
            title: "أنواع الاستفسارات ب API",
          },
          content: `من هنا يمكنك الحصول علي جميع انواع الاستفسارات لإنشاء تذكرة دعم عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="14"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها شكر أو إقتراح والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
      ],
      linkCreateTicket:
        "add-ticket",
      successCreateTicket: '{"success": "1", "feed": "{}"}',
      apiCodeCreateTicekt: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
          "description": "This dummy description please write real description",
          "type": 2,
          "complaint_type_id": 3,
          "complaint_type_title_id": 5,
          "entity_id": 123456789}`,
      dataCreateTicekt: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "description",
          value: "dummy description",
          descryption: "إضافة وصف و توضيح المشكلة",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "type",
          value: "2",
          descryption: "نوع التذكرة 'شكوي'",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "complaint_type_id",
          value: "3",
          descryption: "id نوع الشكوي (يمكنك الحصول عليه من api أنواع الشكاوي)",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "complaint_type_title_id",
          value: "5",
          descryption:
            "id نوع عنوان الشكوي (يمكنك الحصول عليه من api أنواع الشكاوي)",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "entity_id",
          value: "123456789",
          descryption: `يتم إرساله بناء علي نوع الشكوي
            related_model = 0 (إرسال id الطرد),
            related_model = 1 (إرسال id الفرع),
            related_model = 3 (لا يتم إرساله),
            related_model = 4 (إرسال id المهمة)`,
          mandatory: "نعم",
          class: "yes",
        },
      ],
      linkGetComplaints:
        "complains-types",
      successGetComplaints: `
        {"success": "1", "feed": "[{"id": "1", "name_ar": "مشكلة ف طرد", "name_en": "problem in order", "related_model": 0, "titles": [{"id": "1", "type_id": null, "title_ar": "طرد متأخر", "title_en": "delay order"}] }]"}`,
      apiCodeGetComplaints: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }"}`,
      dataGetComplaints: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      linkGetInquiries:
        "inquiries-types",
      successGetInquiries: `
        {"success": "1", "feed": "[{ "id": 1, "name_ar": "بخصوص الربط API", "name_en": "Inquiry" }]"}`,
      apiCodeGetInquiries: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }"}`,
      dataGetInquiries: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      apiCodeCreateInquirie: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
          "description": "This dummy description please write real description",
          "type": 1,
          "inquiry_type_id": 1}`,
      dataCreateInquirie: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "description",
          value: "dummy description",
          descryption: "إضافة وصف و توضيح الاستفسار",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "type",
          value: "1",
          descryption: "نوع التذكرة 'استفسار'",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "inquiry_type_id",
          value: "1",
          descryption:
            "id نوع الاستفسار (يمكنك الحصول عليه من api أنواع الاستفسارات)",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      apiCodeCreateGratitude: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
          "description": "This dummy description please write real description",
          "type": 3 //or 4}`,
      dataCreateGratitude: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "description",
          value: "dummy description",
          descryption: "إضافة وصف و توضيح الإقتراح أو كتابة شكر",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "type",
          value: "3 || 4",
          descryption: `type = 3 ( إنشاء تذكرة نوعها شكر ),
            type = 4 ( إنشاء تذكرة نوعها إقتراح )`,
          mandatory: "نعم",
          class: "yes",
        },
      ],
      linkGetBranches: "get-branches",
      successGetBranches: `{"success": 1, "error_msg": "", "feed": [{"id": 1, "name": "الضبعة-الرئيسي"}], "errors": []`,
      apiCodeGetBranches: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }"}`,
      dataGetBranches: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
    };
  },
  components: {
    LinkApi,
    instantLinkApi,
    Tabs,
    Tab,
  },
  computed: {
    ...mapGetters("userData", ["client"]),
  },
  mounted() {
    this.$watch(
      () => {
        return this.$tours.linkWithApis.currentStep;
      },
      (val) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    );
    if (this.runWalkthrough()) {
      // this.startTour("linkWithApis");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
